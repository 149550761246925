
.buttons-container{
    display:flex;
    justify-content: space-around;
}


.contact-us-stamp{
    width: 30%;
    height: 50px;
    background-color: var(--orange);
    border: none;
    border-radius: 3px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;
}
.contact-us-stamp:hover{
    background-color: var(--dark-orange);    
    color: var(--white);
}
.contact-us-stamp:active {
  text-shadow: var(--white) 1px 0 10px, var(--white) 1px 0 10px;
}

.contact-us-text-container{
    display: none;
}

.contact-us-text{
    font-size: large;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;
}
.contact-us-text:hover{    
    text-shadow: var(--white) 1px 0 10px, var(--white) 1px 0 7px;
    /* cursor: url('./resources/Cursors/copy.png'), auto;	 */
    cursor:pointer;
}

@media screen and (max-width: 800px) {

    .contact-us-stamp{
        font-size: 12px;
    }
}

@media screen and (max-width: 500px) {

    .contact-us-stamp{
        font-size: 10px;
    }
}
