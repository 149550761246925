@import "~react-image-gallery/styles/css/image-gallery.css";
@import url(//db.onlinewebfonts.com/c/6fcade7abbedc0b15b45d46f0b8eca84?family=CoquetteW00-Light);
@font-face {
  font-family: "CoquetteW00-Light";
  src: url("//db.onlinewebfonts.com/t/6fcade7abbedc0b15b45d46f0b8eca84.eot");
  src: url("//db.onlinewebfonts.com/t/6fcade7abbedc0b15b45d46f0b8eca84.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/6fcade7abbedc0b15b45d46f0b8eca84.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/6fcade7abbedc0b15b45d46f0b8eca84.woff") format("woff"), url("//db.onlinewebfonts.com/t/6fcade7abbedc0b15b45d46f0b8eca84.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/6fcade7abbedc0b15b45d46f0b8eca84.svg#CoquetteW00-Light") format("svg");
}

:root {
  --color1: #6A8E7F;
  --color2: #989572;
  --color3: #C6AE82;
  --color4: #D0CFEC;
  --color5: #EDC7CF;
  --black: #222;
  --white: #EEE;
  --gray: #707070;
  --gold: #f0f0a0;
  --red: #ff6c6c;
  --orange: #ffae6c;
  --dark-orange: #fd7627;
 
  --bg-gradient:linear-gradient( 37deg, #f3a38b7a 0%, #e7d6d1 50%, #faf0b6cb 100%);
  /* --bg-gradient: linear-gradient(37deg, rgba(237, 199, 207, 1) 0%, rgba(208, 207, 236, 1) 35%, rgba(156, 232, 255, 1) 100%); */
}

html {
  height: 100%;
  margin: 0 auto;
  background: rgb(255, 225, 179);
  /* background: linear-gradient(35deg, rgba(251,239,220,1) 0%, rgba(230,251,202,1) 74%, rgba(208,251,214,1) 100%); */
  background: var(--bg-gradient);
  background-attachment: fixed;
  overflow-x: hidden;
}

header {
  display: flex;
  justify-content: center;
  background-color: white;
}

body {
  min-height: 100%;
  margin-bottom: -100px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

pre,a {
  font-size: 1.3rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  white-space: pre-wrap;
  text-align: justify;
}

.text-hyperlink{
  font-weight: bold;
}
.text-hyperlink:visited{
  color: blue;
}

textarea {
  width: 99%;
  height: 300px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
}

input:invalid {
  border: 1px solid var(--red);
}

input:valid {
  border: 1px solid black;
}

*::selection {
  background: rgba(0, 0, 0, 0.1);
}

.loader {
  background-color: aqua;
  height: 1000px;
}

nav ul li {
  font-weight: bold;
}

p::selection {
  background: rgba(0, 0, 0, 0.1);
}

.presentation-container p::selection {
  color: var(--white);
}

.presentation-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0 auto;
  text-align: justify;
}

.presentation-container p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.parenthesis{
  font-size: 1rem;
  font-style: italic;
}

.inline{
  display: inline;
}


.legend .presentation-container .presentation {
  font-size: 28px;
  font-family: "CoquetteW00-Light", sans-serif;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}


@media screen and (max-width: 800px) {
  pre, p, a {
    font-size: 15px;
  }
  .presentation-container {
    width: 70%;
  }
}

@media screen and (max-width: 500px) {
  pre, p {
    font-size: 8px;
  }
  .legend .presentation-container .presentation {
    font-size: 18px;
  }
  .presentation-container {
    width: 80%;
  }
}