.cattery-cat-list ul {
    list-style: none;
}

.cattery-text {
    text-align: justify;
    width: 60%;
    margin: 50px auto;
}

.cat-container {
    width: 90%;
    margin: 20px auto;
    background-color: var(--white);
    border-radius: 2px;
}

.cat-container-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.cat-container-name {
    margin: 10px;
    font-size: 1.2vw;
    font-weight: bold;
}

.cat-container-body {
    display: flex;
    justify-content: space-around;
    margin: 10px;
}

.cat-container-presentation {
    font-size: 15px;
    width: 60%;
    margin-right: 30px;
    text-align: justify;
}

.cat-container-image {
    width: 40%;
    height: 50%;
    margin-bottom: 10px;
    border-radius: 6%;
}
.image-gallery{
    width: 40%;
    height: 50%;
    margin-bottom: 10px;
    border-radius: 6%;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
  height: 20px;
}

.cat-container-image-pedigree {
    width: 2vw;
    height: 3vw;
    transition: 0.2s;
    cursor: pointer;
}

.cat-container-image-pedigree:hover {
    cursor: pointer;
    box-shadow: var(--black) 0 0 10px;
}

.cat-container-details {
    margin: 10px;
    font-size: 1.2vw;
    font-style: italic;
}

.cat-container-color {
    margin: 10px;
    font-size: 1.1vw;
}

@media screen and (max-width: 900px) {
    .cat-container {
        width: 100%;
    }
    .cat-container-image {
        width: 80%;
        height: 80%;
    }
    .cat-container-body {
        flex-wrap: wrap-reverse;
    }
    .cat-container-presentation {
        width: 90%;
        margin: 5px auto;
        font-size: 8px;
    }
    .cat-container-title {
        padding: 3px;
        flex-wrap: wrap;
    }
    .cat-container-name {
        margin: 3px;
        font-size: 8px;
    }
    .cat-container-details {
        font-size: 5px;
        margin: 3px;
    }
    .cat-container-color {
        margin: 3px;
    }
}