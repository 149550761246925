.navigation-header {
  z-index: 99;
  position: sticky;
  top: 0;
}

.navbar {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 10px;
  display: flex;
  justify-content: space-around;
  align-self: flex-start;
  height: auto;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--color3);
  border: solid 2px var(--color2);
  overflow: hidden;
}

.navbar li {
  list-style: none;
}

.navbar li a {
  display: block;
  color: var(--black);
  text-align: center;
  padding: 14px 1vw;
  text-decoration: none;
  transition: 0.150s;
}

.navbar a:visited {
  text-decoration: none;
}

.navbar a:hover {
  color: var(--white);
  text-shadow: var(--white) 1px 0 10px;
}

.navbar a:active {
  text-shadow: var(--white) 1px 0 10px, var(--white) 1px 0 10px;
}

.navbar .selected {
  color: var(--white);
}

@media screen and (max-width: 800px) {
  .navbar li a {
    font-size: 60%;
  }
}

@media screen and (max-width: 400px) {
  .navbar li a {
    font-size: 7px;
  }
}

@media screen and (max-width: 800px) {
  pre, p {
    font-size: 15px;
  }
}