.map-container {
    position: relative;
    width: 50%;
    height: 500px;
    margin-bottom: 100px;
}

.map {
    border: solid var(--black) 2px;
    border-radius: 3px;
}

@media screen and (max-width:900px) {
    .map-container {
        width: 70%;
    }
}

@media screen and (max-width:500px) {
    .map-container{        
        height: 300px;
    }
}