.modal {
    font-size: 12px;
  }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
  .modal > .content {
    width: 100%;
    padding: 10px 5px;
  }
  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }
  .popup-content {
    border-radius: 5px;
    padding: 0.5rem;
  }
  
  html[data-theme='dark'] .popup-content {
    background-color: rgb(41, 45, 62);
    color: #fff;
    border: 1px solid #9a9595;
  }
  
  html[data-theme='light'] .popup-content {
    background-color: #fff;
    color: #000;
    border: 1px solid #d7d7d7;
  }
  
  html[data-theme='dark'] .popup-arrow > svg {
    color: rgb(41, 45, 62);
    stroke-width: 2px;
    stroke: #9a9595;
    stroke-dasharray: 30px;
    stroke-dashoffset: -54px;
  }
  
  html[data-theme='light'] .popup-arrow > svg {
    color: #fff;
    stroke-width: 2px;
    stroke: #d7d7d7;
    stroke-dasharray: 30px;
    stroke-dashoffset: -54px;
  }