.photo-container {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    column-gap: 10px;
    row-gap: 10px;
}

.photo {
    width: 70%;
    margin: 0 auto;
    padding: 10px;
    background-color: var(--white);
    border: solid 1px var(--gray);
    border-radius: 5px;
    transition: 0.2s;
    user-select: none;
}

.photo:nth-child(1) {
    transform: rotate(10deg) translate(10px, 10px);
}

.photo:nth-child(2) {
    transform: rotate(-5deg) translate(5px, 0px);
}

.photo:nth-child(3) {
    transform: rotate(10deg) translate(0px, 5px);
}

.photo:nth-child(4) {
    transform: rotate(0deg) translate(-5px, -10px);
}

.photo:nth-child(5) {
    transform: rotate(5deg) translate(0px, 0px);
}

.photo:nth-child(6) {
    transform: rotate(-2deg) translate(5px, 10px);
}

.photo:nth-child(7) {
    transform: rotate(0deg) translate(0px, 5px);
}

.photo:nth-child(8) {
    transform: rotate(-15deg) translate(0px, 5px);
}

.photo:nth-child(9) {
    transform: rotate(-2deg) translate(10px, 5px);
}

.photo:nth-child(10) {
    transform: rotate(5deg) translate(-5px, 10px);
}

.photo:nth-child(11) {
    transform: rotate(5deg) translate(0px, 0px);
}

.photo:hover {
    transform: rotate(0deg) translate(0px, 0px) scale(1.2);
    z-index: 3;
    cursor: pointer;
}

.photo p {
    margin: 0;
    font-weight: bold;
}

@media screen and (min-width:1700px) {

    .photo-container {
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(4, minmax(360px, 1fr));
        column-gap: 10px;
        row-gap: 10px;
    }
    
}