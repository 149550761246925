 .footer {
     position: relative;
     width: 100%;
     height: 100px;
     margin-top: auto;
     background-color: var(--color3);
     border: solid 2px var(--color2);
 }

 .social-wrapper {
     display: inline-flex;
     position: absolute;
     top: 10%;
     left: 50%;
     transform: translateX(-50%);
 }

 .social-wrapper a {
     color: var(--black);
 }

 .social-wrapper a:visited {
     color: var(--black);
 }

 .social-wrapper .icon {
     margin: 0 20px;
     cursor: pointer;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     position: relative;
     z-index: 2;
 }

 .social-wrapper .icon span {
     position: relative;
     z-index: 2;
     height: 60px;
     width: 60px;
     display: block;
     background: #fff;
     box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
     border-radius: 50%;
     text-align: center;
     transition: 0.2s;
 }

 .social-wrapper .icon span i {
     font-size: 30px;
     line-height: 60px;
 }

 .social-wrapper .facebook:hover span {
     background: #3B5999;
     color: var(--white);
 }

 .social-wrapper .whatsapp:hover span {
     background: rgb(0, 167, 53);
     background: linear-gradient(0deg, rgba(0, 167, 53, 1) 0%, rgba(0, 255, 131, 1) 100%);
     color: var(--white);
 }

 .social-wrapper .instagram:hover span {
     background: #d6249f;
     background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
     color: var(--white);
 }

 @media screen and (max-width: 800px) {
     .social-wrapper .icon span {
         height: 50px;
         width: 50px;
     }
     .social-wrapper .icon span i {
         font-size: 22px;
         line-height: 50px;
     }
     .footer {
         height: 70px;
     }
 }