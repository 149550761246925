.side-bar-page {
    display: flex;
}

.nav-menu {
    position: relative;
    height: 100vh;
}

.sidenav {
    position: absolute;
    height: 100%;
    width: 17vw;
    background-image: linear-gradient(0deg, #ceaf80cb, #e7d6d1);
    filter: brightness(1.1);
    border: solid 3px var(--color3);
}

.page-content {
    width: 76%;
    height: 100vh;
    margin-left: 20vw;
    padding-right: 40px;
    text-align: justify;
    overflow-y: scroll;
    scroll-behavior: smooth;
}


.page-content::-webkit-scrollbar {
    width: 15px;
}

.page-content::-webkit-scrollbar-thumb {
    background-color: var(--black);
    border-radius: 10px;
}

.nav-menu ul {
    position: sticky;
    top: 0;
    z-index: 100;
    margin: 0;
    padding: 0;
}

.nav-menu li {
    list-style: none;
}

.nav-menu-link {
    text-align: center;
    margin: 40px 1vw;
}

.nav-menu a {
    display: block;
    color: var(--black);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    transition: 0.150s;
}

.nav-menu a:visited {
    text-decoration: none;
}

.nav-menu a:hover {
    color: var(--white);
    text-shadow: var(--white) 1px 0 10px;
    cursor: pointer;
}

.nav-selector{
    display: none;
    transition: 0.2s;
}

.nav-active{
    display: block;
    animation: fadeIn linear 0.5s;
}


@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  

@media screen and (max-width: 800px) {
    .nav-menu-link {
        font-size: 12px;
        margin: 20px 1vw;
    }
    .nav-menu a {
        padding: 10px 1px;
        font-size: 12px;
    }
}

@media screen and (max-width: 500px) {
    .nav-menu-link {
        margin: 10px 1vw;
        font-size: 10px;
        font-weight: bold;
    }
    .nav-menu a {
        padding: 10px 1px;
        font-size: 8px;
    }
    .page-content {
        margin-left: 21vw;
    }
}