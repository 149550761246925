.not-found {
    width: 90%;
    margin: 20px auto 200px auto;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: ". .";
}

.not-found-text {
    padding-left: 20px;
}