.presentation-text-kittens {
    display: inline;
    margin: 20px auto;
}

.kitten-list ul {
    list-style: none;
    list-style-position: inside;
    margin: 0;
    padding: 0;
}

.wedding-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    background-color: var(--white);
    border-radius: 3px;
}

.wedding-cat-container {
    width: 80%;
}

.wedding-cat-container-image {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 6%;
}

.wedding-date {
    text-align: center;
    margin-top: 3px;
}

.kitten-container {
    position: relative;
    display: flex;
    width: 40%;
    margin: 20px auto;
    background-color: var(--white);
    border-radius: 2px;
}

.kitten-container-image {
    width: 30%;
    margin: 20px;
    border-radius: 6%;
}

.kitten-gender img {
    position: absolute;
    top: 0;
    right: 0;
    width: 5%;
    margin: 5px;
}

.kitten-details {
    width: 50%;
}

.kitten-title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 20px 0 0 5px;
}

.kitten-name {
    font-size: 1.6vw;
    font-weight: bold;
}

.kitten-color {
    font-size: 1.1vw;
}