.heart-beating {
    width: 50%;
    margin: 50px auto 0 auto;
    animation: 1.5s infinite beatHeart;
}

@keyframes beatHeart {
    0% {
        transform: scale(1);
    }
    10% {
        transform: scale(1.1);
    }
    16% {
        transform: scale(1);
    }
    24% {
        transform: scale(1.1);
    }
    40% {
        transform: scale(1);
    }
}