.information-image-container-list {
    list-style: none;
    list-style-position: inside;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
}

.information-image-container-list-small {
    list-style: none;
    list-style-position: inside;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 0 auto;
}

.information-image-container li {
    margin: 30px;
}

.information-image-container img {
    border: solid 2px var(--black);
    border-radius: 3px;
}

@media screen and (max-width:1000px) {
    .information-image-container-list {
        flex-direction: column;
    }
    .information-image-container li {
        margin: 10px;
    }
}